<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-overlay
          :show="item === null"
          rounded="sm"
        >
          <b-card v-if="item">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('form.type.label')"
                    label-for="key"
                  >
                    <v-select
                      v-model="item.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="paymentTypes"
                      :reduce="val => val.value"
                      :clearable="false"
                      :state="errors && errors.type ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.type">
                      {{ errors.type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    :label="'Націнка загальна (множитель)'"
                    label-for="markup"
                  >
                    <b-form-input
                      :id="'markup'"
                      v-model="item.markup"
                      type="number"
                      min="0"
                      max="99"
                      step="0.01"
                      :state="errors && errors.markup ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.markup">
                      {{ errors.markup[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('form.position.label')"
                    label-for="position"
                  >
                    <b-form-input
                      :id="'position'"
                      v-model="item.position"
                      type="number"
                      min="0"
                      step="1"
                      :state="errors && errors.position ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.position">
                      {{ errors.position[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('form.by_default.label')"
                    label-for="by_default"
                  >
                    <v-select
                      id="by_default"
                      v-model="item.by_default"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="[
                        { label: this.$t('general.statuses.enabled'), value: 1 },
                        { label: this.$t('general.statuses.disabled'), value: 0 },
                      ]"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="by_default"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.by_default">
                      {{ errors.by_default[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="hidden"
                >
                  <b-form-group :label="$t('form.avatar.label')">
                    <b-media no-body>
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                            height="80"
                          />
                        </b-link>
                        <!--/ avatar -->

                        <!-- reset -->
                        <b-button
                          v-if="item.avatar"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.avatar = null"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                        <!--/ reset -->
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <InitFileManager
                          field="avatar"
                          type="payment_method_avatar"
                          :multiple="false"
                          @fm-selected="selectedAvatar"
                        />

                        <b-row
                          v-if="errors && errors.avatar"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors.avatar[0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="errors && errors['avatar.type']"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors['avatar.type'][0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>

                      </b-media-body>
                    </b-media>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('form.services.label')"
                    label-for="services"
                    :state="errors && errors.services ? false : null"
                  >
                    <v-select
                      id="services"
                      v-model="item.services"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="servicesOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      :multiple="true"
                      input-id="services"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.services">
                      {{ errors.services[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.description.label')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                      >
                        <b-form-textarea
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>

              <!-- Action Buttons -->
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="onSubmit"
              >
                {{ $t('general.save') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                :to="{ name: 'payment_methods-index' }"
              >
                {{ $t('general.cancel') }}
              </b-button>
            </b-form>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="8"
      >
        <b-overlay
          :show="item === null"
          rounded="sm"
        >
          <b-card v-if="item">
            <List
              :parent-id="item.id"
              :languages="languages"
              :can-add="canAddSubTypes"
            />
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import List from './values/List'

export default {
  components: {
    List,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      item: null,
      languages: [],
      servicesOptions: [],
    }
  },
  computed: {
    selectedType() {
      return _.find(this.paymentTypes, type => type.value === this.item.type)
    },
    canAddSubTypes() {
      return !!this.selectedType?.gateways.length
    },
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/admin/payment_methods/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'payment_methods-index' })
        }
      })

    if (!Object.keys(this.servicesOptions).length) {
      await this.$http.get('/api/admin/products', { params: { type: 'service', per_page: 100 } })
        .then(response => {
          this.servicesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    }
  },
  methods: {
    selectedAvatar(data) {
      this.item.avatar = data
    },
    onSubmit() {
      this.$http.put(`/api/admin/payment_methods/${this.item.id}`, this.item)
        .then(() => {
          router.replace({ name: 'payment_methods-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        status: item.status,
        type: item.type,
        markup: item.markup,
        position: item.position,
        by_default: item.by_default ? 1 : 0,
        title: {},
        description: {},
        services: _.map(item.services, 'id'),
        avatar: this.$options.filters.singleMedia(item.avatar),
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
      })

      return data
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
